import { UrlService } from '@uirouter/core';

import { ApiModel }                        from '../api/models/api.model';
import { ApiServiceAjs }                   from '../api/api.service.ajs';
import { SessionServiceAjs }               from '../sessions/session.service.ajs';
import { XeroChartOfAccountsModel }        from './models/xero.chart-of-accounts' ;
import { XeroSettingsModel }               from './models/xero.settings';
import { XeroTaxRateModel }                from './models/xero.tax-rate';
import { XeroTrackingCategories }          from './models/xero.tracking-categories';
import { XeroTrackingCategoryWithOptions } from './models/xero.tracking-categories.with-options';

import SyncXeroHtml  from './xero.sync.html';

export class XeroServiceAjs {
  $q         : ng.IQService;
  $uibModal  : ng.ui.bootstrap.IModalService;
  $urlService: UrlService;
  API        : ApiModel;
  api        : ApiServiceAjs;
  sessionApi : SessionServiceAjs;

  loginUrl = 'https://login.xero.com/identity/connect/authorize';

  constructor (
    $q           : ng.IQService,
    $uibModal    : ng.ui.bootstrap.IModalService,
    $urlService  : UrlService,
    API          : ApiModel,
    sessionObjAPI: SessionServiceAjs,
    workshopAPI  : ApiServiceAjs
  ) {
    this.$q          = $q;
    this.$uibModal   = $uibModal;
    this.$urlService = $urlService;
    this.api         = workshopAPI;
    this.API         = API;
    this.sessionApi  = sessionObjAPI;
  }

  accounts () : ng.IPromise<XeroChartOfAccountsModel> {
    return this.api.get('/xero/chart_of_accounts');
  }

  addXeroTax ( tax : string ) : ng.IPromise<any> {
    return this.api.post('/xero/adjust_tax_rates', tax, 'tax_rate');
  }

  checkExistingIntegrations () : ng.IPromise<string> {
    return this.api.get('/company/accounting_ids_exist/xero');
  }

  clearID () : ng.IPromise<boolean> {
    return this.api.post('/company/clear_accounting_contact_ids', { accounting_integration: 'xero', confirmation: 'CLEAR IDS' });
  }

  getTaxRates () : ng.IPromise<Array<XeroTaxRateModel>> {
    return this.settings()
    .then(( settings : XeroSettingsModel ) => angular.isArray(settings.tax_rates)
      ? settings.tax_rates
      : []
    );
  }

  getTrackingCategories () : ng.IPromise<Array<XeroTrackingCategories>> {
    return this.api.get('xero/get_tracking_categories');
  }

  getTrackingCategoryWithOptions () : ng.IPromise<XeroTrackingCategoryWithOptions> {
    return this.api.get('xero/get_tracking_options');
  }

  settings () : ng.IPromise<XeroSettingsModel> {
    return this.api.get('xero/settings', null, null, {
      timeout: 30000
    });
  }

  shareAccount ( shareAccountFlag : boolean ) : ng.IPromise<any> {
    return this.api.get('/company/share_accounting', [ shareAccountFlag ? 't' : 'f' ]);
  }

  sync () : ng.IPromise<number> {
    return this.$uibModal.open({
      controller : 'SyncXeroCtrl',
      templateUrl: SyncXeroHtml,
      windowClass: 'flip-container'
    }).result
    .then(( conflicts : string ) => parseInt(conflicts));
  }
}